import React from 'react'
import Careers from './Careers'

export default function index() {
  return (
    <>
    <Careers />
    </>
  )
}

