
import React from 'react';
import {  Route, Routes, Link } from 'react-router-dom' //Navigate,
// Pages 
// import Students from './Students'
// Icons 
import { MenuOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { UserOutlined, MailOutlined,  } from '@ant-design/icons'; //CheckCircleOutlined, UploadOutlined,
import {  FaCalendarAlt } from '../../../node_modules/react-icons/fa'; //FaSearch,
import {  Layout, Menu, theme } from 'antd'; //Button,
import { AiOutlineBars } from '../../../node_modules/react-icons/ai'; //, AiTwotoneWallet
import { IoMdContact } from "../../../node_modules/react-icons/io";
import { FaLocationDot } from "../../../node_modules/react-icons/fa6";
import { MdOutlineAnnouncement } from "../../../node_modules/react-icons/md";
// Pages 
import Home from './Home'
import UpdateUser from "./Home/UpdateTodo"
import AddProducts from './AddProducts';
import NoPage from '../../components/NoPage'
import Contact from './Contact';
import Locations from './Locations';
import UpdateLoction from './Locations/UpdateLocation';
import Anouncement from './Announcements';
import UpdateAnouncements from './Announcements/UpdateAnouncements';
import Categories from './Categories';
import UpdateCategories from './Categories/UpdateCategories';
import Products from './Products';
import UpdateProducts from './Products/UpdateProduct';
import Careers from './Careers';
import UpdateCareers from './Careers/UpdateCareers';
import Message from './Message';
import PriceList from './PriceList';
import UpdatePriceList from './PriceList/UpdatePriceList';
// import Datatable from '../../../Components/Datatable/Datatable';
// import { auth, firestore } from '../../config/firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { collection, getDocs } from 'firebase/firestore';

const { Header, Content, Footer, Sider } = Layout;
const year = new Date().getFullYear()

 export default function Hero() {
  const tabs = [{ icon: DoubleRightOutlined, name: "Products", path: 'products' },{ icon: FaCalendarAlt, name: "Add Products", path: 'add-products' }, { icon: AiOutlineBars, name: "Categories", path: 'categories' },  { icon: MailOutlined, name: "Messages", path: 'messages' }, { icon: IoMdContact, name: "Contact", path: 'contact' },{ icon: FaLocationDot, name: "Locations", path: 'locations' },{ icon: UserOutlined, name: "Profile", path: 'profile' },{ icon: MdOutlineAnnouncement, name: "Anouncement", path: 'announcement' },{ icon: MdOutlineAnnouncement, name: "Price list", path: 'price-list' },{ icon: MdOutlineAnnouncement, name: "Careers", path: 'careers' }]

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout hasSider>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
        }}
        onCollapse={(collapsed, type) => {
        }}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="row d-flex justify-content-between w-100 ms-1 mt-3">
          <div className="col-6 mt-2">
            <strong style={
              {
                color: "white",
                paddingTop: "20px",
                marginTop: "40px !important",
                fontFamily: "sans-serif",
                fontSize: "20px"
              }
            }>Dashboard</strong>
          </div>
          <div className="col-4 text-end py-2">
            <MenuOutlined />
          </div>
        </div>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['0']}
          items={tabs.map(
            (object, index) => ({
              key: String(index + 1),
              icon: React.createElement(object.icon),
              label: <Link className='text-decoration-none' to={object.path}>{object.name}</Link>,
            }),
          )}
        />
      </Sider>
      <Layout
      className="site-layout"
      style={{
        marginLeft: 200,
      }}>
        <Header style={{ padding: 0, background: colorBgContainer }} >
          <button className='btn btn-primary ms-3'><Link className='text-decoration-none text-white' to="/" >Home</Link></button>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          <div className='h-100'><div className="container">
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/users/:id' element={<UpdateUser />} />
              <Route path="products" element={<Products />} />
              <Route path='/products/:id' element={<UpdateProducts />} />
              <Route path="add-products" element={<AddProducts />} />
              <Route path="contact" element={<Contact />} />
              <Route path="locations" element={<Locations />} />
              <Route path='/locations/:id' element={<UpdateLoction />} />
              <Route path="announcement" element={<Anouncement />} />
              <Route path="/announcement/:id" element={<UpdateAnouncements />} />
              <Route path="categories" element={<Categories />} />
              <Route path="categories/:id" element={<UpdateCategories />} />
              <Route path="messages" element={<Message />} />
              <Route path="careers" element={<Careers />} />
              <Route path="careers/:id" element={<UpdateCareers />} />
              <Route path="price-list" element={<PriceList />} />
              <Route path="price-list/:id" element={<UpdatePriceList />} />
              
              <Route path="*" element={<NoPage />} />
            </Routes>
          </div>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}> <p className="mb-0 text-medium text-center">&copy; {year} VOLTEC. All Rights Reserved.</p></Footer>
      </Layout>
    </Layout>
  );
};

