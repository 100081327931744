import React, { useEffect, useState } from 'react'
import {   Divider } from 'antd'
import { collection, getDocs, query, where } from 'firebase/firestore'
import { firestore } from 'config/firebase'

export default function Hero() {
  const [documents, setDocuments] = useState([])

  const getLocations = async () => {
    const q = query(collection(firestore, "locations"),where("status", "==", 'active'))
    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setDocuments(array)
  }
  useEffect(() => {
    getLocations()
  }, [])
  return (
    <>
      <div className='py-5'>
        <div className="container">
        <div className="row">
              <div className="col d-flex flex-column">
                <h2 className="text-center">For Any Queries</h2>
                <Divider />
                <div>
                  <h3 className="">Name: Dr Muhammad Suleiman</h3>
                  <h5 className="text-primary">Chief Executive Officer</h5>
                  <h4 className="">
                    Contact:
                    <a
                      href="tel:+923007508771"
                      className="text-dark text-decoration-none"
                    >
                      +92 300 7508771
                    </a>
                  </h4>
                  <Divider />
                  <h3 className="">Name: Dr Muhammad Amin</h3>
                  <h5 className="text-primary">Admin Voltec Group Pakistan</h5>
                  <h4 className="">
                    Contact:
                    <a
                      href="tel:+923336733340"
                      className="text-dark text-decoration-none"
                    >
                      +92 333 6733340
                    </a>
                  </h4>
                    <Divider />
                  <h3 className="">Name: Asad Sajjad</h3>
                  <h5 className="text-primary">GM of Sales & Marketing</h5>
                  <h4 className="">
                    Contact:
                    <a
                      href="tel:+923051348912"
                      className="text-dark text-decoration-none"
                    >
                      +92 305 1348912
                    </a>
                  </h4>

                  <Divider />
                  <h3>Name: Muhammad Aaqib Arshad</h3>
                  <h5 className="text-primary">Director of IT Department </h5>
                  <h4 className="">
                    Contact:
                    <a
                      href="tel:+923116645561"
                      className="text-dark text-decoration-none"
                    >
                      +92 311 6645561
                    </a>
                  </h4>

                  <Divider />
                  <h3 className="">Name: Rana Nadeem Ahmad</h3>
                  <h5 className="text-primary">Director of Marketing</h5>
                  <h4 className="">
                    Contact:
                    <a
                      href="tel:+923457610717"
                      className="text-dark text-decoration-none"
                    >
                      +92 345 7610717
                    </a>
                  </h4>
                </div>
              </div>
            </div>
          <div className="row">
            <div className="col text-center">
              <Divider />
              <h1>Our Offices</h1>
              <Divider />
            </div>
          </div>
          <Divider />
          <div className="row">
            <div className="col">
              <div className="px-5">
                    {documents.map((obj, i) => {
                      return (
                        <div key={i}>
                          <div dangerouslySetInnerHTML={{__html:obj.location}}></div>
                        </div>
                      )
                    })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
