import React from 'react'
import PriceList from './Pricelist'

export default function index() {
  return (
    <>
    <PriceList />
    
    </>
  )
}
