import React from 'react'
import Announcements from './Anouncement'

const index = () => {
  return (
    <>
    <Announcements />
    </>
  )
}

export default index