import React, { useEffect, useState } from 'react'
import {  Button, Divider, Space, Tooltip, message } from 'antd'
import { DeleteOutlined } from "@ant-design/icons"
import dayjs from 'dayjs'
// import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query } from 'firebase/firestore'
import { firestore } from 'config/firebase'
// import { useAuthContext } from 'contexts/AuthContext'

export default function Hero() {
  const [documents, setDocuments] = useState([])
  const getTodos = async () => {

    const q = query(collection(firestore, "messages"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    // setAllDocuments(array)
    setDocuments(array)
  }

  useEffect(() => {
    getTodos()
  }, [])
  const handleDelete = async (loc) => {

    try {
      await deleteDoc(doc(firestore, "messages", loc.id));

      let documentsAfterDelete = documents.filter(doc => doc.id !== loc.id)
      setDocuments(documentsAfterDelete)

      message.success("Message deleted successfully")
    } catch (err) {
      console.error(err)
      message.error("something went wrong while delting Message")
    }
  }

  return (
    <>
      <div className='py-5'>
        <div className="container">
          <div className="row">
            <h1 className='text-center'>Messages</h1>
            <Divider />
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Message</th>
                      <th>Date Sended</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((obj, i) => {
                      return (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td>{obj.fullname}</td>
                          <td>{obj.email}</td>
                          {/* <td>{obj.dateCreated}</td> */}
                          <td><div dangerouslySetInnerHTML={{__html:obj.Message}}></div></td>
                          <td>{obj.dateCreated ? dayjs(obj.dateCreated).format("dddd, DD/MM/YYYY") : ""}</td>
                          <td>
                            <Space>
                              <Tooltip title="Delete" color='red'><Button danger icon={<DeleteOutlined />} onClick={() => { handleDelete(obj) }} /></Tooltip>
                              {/* <Tooltip title="Edit"><Button type="primary" icon={<EditOutlined />} onClick={() => { navigate(`/dashboard/locations/${obj.id}`) }} /></Tooltip> */}
                              <a href={`mailto:${obj.email}`} className=''><button className='btn btn-primary'>Reply</button></a>
                            </Space>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
