import React from 'react'
import Login from './Login'

const index = () => {
  return (
    <>
    <Login />
    </>
  )
}

export default index