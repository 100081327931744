import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from 'contexts/AuthContext'

export default function PrivateRoute({ Component }) {
    const { isAuth } = useAuthContext()
    const location = useLocation()
    const dashboardRegex = /^\/dashboard\/[A-Za-z0-9]*$/;

    if ( !isAuth && (dashboardRegex.test(location.pathname) || location.pathname==="/dashboard"))
        return <Navigate to="/auth/login" state={{ from: location.pathname }} replace />

    return (
        <Component />
    )
}
