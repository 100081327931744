import React from 'react'
import {FaFacebookF} from '../../../node_modules/react-icons/fa6'
import {FaTwitter,FaInstagram} from '../../../node_modules/react-icons/fa'
import {ImYoutube2} from '../../../node_modules/react-icons/im'
import { Link } from 'react-router-dom'
const TopBar = () => {
  return (
    <>
    <div className="container-fluid bg-primary">
        <div className="row">
         
            <div className="col d-flex justify-content-center align-items-center my-1">
            <Link to="careers" className='text-white text-decoration-none fw-bold mx-sm-1 px-1 pe-sm-2' style={{borderRight:'1px solid white'}}>Careers</Link>
            <Link to="support" className='text-white text-decoration-none fw-bold mx-sm-1 px-1 pe-sm-2' style={{borderRight:'1px solid white'}}>Support</Link>
              <a href="https://www.facebook.com/profile.php?id=61553480411168" className='mx-sm-2 mx-1' target='_blank' rel="noopener noreferrer"><FaFacebookF className='text-white fs-5' /></a>
              <a href="https://twitter.com/Voltec_Group" className='mx-sm-2 mx-1' target='_blank' rel="noopener noreferrer"><FaTwitter className='text-white fs-5' /></a>
              <a href="https://www.instagram.com/voltec.group/" className='mx-sm-2 mx-1' target='_blank' rel="noopener noreferrer"><FaInstagram className='text-white fs-5' /></a>
              <a href="https://www.youtube.com/@VoltecGroup" className='mx-sm-2 mx-1' target='_blank' rel="noopener noreferrer"><ImYoutube2 className='text-white fs-1' /></a>
            </div>
        </div>
    </div>
    
    </>
  )
}

export default TopBar

