import React from 'react'
import Support from './Support'

const Index = () => {
  return (
    <>
    <Support />
    </>
  )
}

export default Index