import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, Row, Select, Typography, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'


const { Title } = Typography
export default function UpdateCategories() {

  const [state, setState] = useState({})
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }))

  const getDocument = useCallback(async () => {
    const docRef = doc(firestore, "PriceList", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()){
      const obj = docSnap.data()
      setState(obj)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("PriceList not found!")
    }
  }, [params.id])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let {  status,productName,modelNumber,price } = state
    const UpCat = {
      ...state,
      productName,modelNumber,
       price,  status,
      dateModified: new Date().getTime(),
    }
// console.log('Uploc', Uploc)
    setIsProcessing(true)
    try {
      await setDoc(doc(firestore, "PriceList", UpCat.id), UpCat);
      message.success("Price List updated successfully!")
      navigate("/dashboard/price-list")
    } catch (e) {
      message.error('Something went wrong while updating!')
    }
    setIsProcessing(false)
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className='m-0 text-center'>Update Price List</Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter="16">
                <Col xs={24} lg={12}>
                    <Form.Item label="Product Name">
                      <Input placeholder='Enter the product name...' value={state.productName} name='productName' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Model Number">
                      <Input placeholder='Enter the model number...' value={state.modelNumber} name='modelNumber' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Price">
                      <Input placeholder='Enter the price of the product...' value={state.price} name='price' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                   
                   
                <Col xs={24} md={12}>
                    <Form.Item label="Status">
                      <Select value={state.status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
            <Divider />
                  <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >
                    <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleSubmit}>Update Price List</Button>
                  </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
