import { Divider } from 'antd';
import { firestore } from 'config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const Pricelist = () => {
  const [documents, setDocuments] = useState([]);
  const getPriceList = async () => {
    const q = query(
      collection(firestore, "PriceList"),
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data();
      array.push(data);
    });
    setDocuments(array);
  };
  useEffect(() => {
    getPriceList();
  }, []);
  return (
    <>
    <div className="container mb-3">
      <div className="row flex-column">
        <h1 className='text-center my-4'>Price List</h1>
        <Divider />
       <div className="col-md-2">
       <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-primary my-3"
                    table="table-to-xls"
                    filename="table"
                    sheet="Price_List"
                    buttonText="Download as Excel"/>
       </div>
      <div className="col">
      
      <div className="table-responsive">
  <table className="table table-bordered align-middle" id='table-to-xls'>
    <thead>
      <tr>
        <th>#</th>
        <th>Product Name</th>
        <th>Model Number</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
    
      {documents.map((obj, i) => {
                    return (
                      
      <tr key={i}>
        <td>{i+1}</td>
        <td>{obj.productName}</td>
        <td>{obj.modelNumber}</td>
        <td>{obj.price}</td>
      </tr>
   
                    );

                  })}

</tbody>
  </table>
</div>
                </div>
              </div>
            </div>
    </>
  )
}

export default Pricelist