import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "config/firebase";
import { Avatar, Skeleton} from "antd";
import {ProfileOutlined} from "@ant-design/icons"
const Products = () => {
  // const [allDocuments, setAllDocuments] = useState([])
  const [documents, setDocuments] = useState(null)
  // const [catDocuments, setCatDocuments] = useState([])
  // const [category, setCategory] = useState("All")
  const navigate = useNavigate()


  const getProducts = async () => {
    const q = query(collection(firestore, "Products"),where("status", "==", 'active'))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    // setAllDocuments(array)
    setDocuments(array)
  }
 
  // const getCategories = async () => {
  //   const q = query(collection(firestore, "Categories"));

  //   const querySnapshot = await getDocs(q);
  //   const array = [];
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     let data = doc.data();
  //     array.push(data);
  //   });
  //   setCatDocuments(array);
  
    // setDocuments(array)
  // };
  // useEffect(() => {
  //   if(category ==="All"){
  //     setDocuments(allDocuments)
  //   }
  //   else{
  //     const filteredDocuments = allDocuments.filter(obj => obj.category === category)
  //   setDocuments(filteredDocuments)
  //   }
  // }, [allDocuments, category])
  useEffect(() => {
   setInterval(() => {
    getProducts()
   }, 5000);
  },[])
  return (
    <>
   <div className="container">
    {/* <Skeleton style={{display:activeSkeleton}} active /> */}
    {/* <Skeleton active size={50} /> */}
    {/* <div className="row">
    <Col xs={24} lg={8}>
                    <Form.Item label="Categories">
                      <Select
                        value={category}
                        onChange={(cat) => setCategory(cat) }
                      >
                         <Select.Option value={"All"}>
                            All
                          </Select.Option>
                        {catDocuments.map((obj, i) => {
                          return (
                           
                            <Select.Option key={i} value={obj.Category_name}>
                            {obj.Category_name}
                          </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
    </div> */}
    {/* <Divider /> */}
    {!documents && (<>
      <Skeleton active /><Skeleton active /><Skeleton active /><Skeleton active /></>) }
    {documents && ( <div className="row justify-content-center">
      {
        documents.map((obj,index)=>{
          return(
            <div className="col-md-4 my-4" key={index}>
      <div className="card card-shadow h-100">
      {obj.file ? <img src={obj.file} className='card-img-top' style={{maxHeight:300,objectFit:'fill'}} alt="Product"/> : <Avatar icon={<ProfileOutlined />} />}
        <div className="card-body d-flex flex-column">
          <h5 className="card-title">{obj.product_name}</h5>
          <h6 className="card-title">{obj.category}</h6>
          <p className="card-text">{obj.short_description}</p>
          <div className="mt-auto">
          <button onClick={() => { navigate(`/products/${obj.id}`) }} className="btn btn-primary">
            learn More
          </button>
          </div>
        </div>
      </div>
      </div>
        )
        })
      }
    </div>) }
   
   </div>
    </>
  );
};

export default Products;
