import React from 'react'
import Contact from './Contact'

const Index = () => {
  return (
    <>
    <Contact />
    </>
  )
}

export default Index