import React from 'react'
import AddProducts from './AddProducts'

const Index = () => {
  return (
    <>
    <AddProducts />
    </>
  )
}

export default Index