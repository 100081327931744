import React, { useEffect, useState } from 'react'
import {  Button, Divider, Form, Select, Space,Typography, Tooltip, message, Col } from 'antd'
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useAuthContext } from 'contexts/AuthContext'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query, setDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'
// import { useAuthContext } from 'contexts/AuthContext'

// React Full Tool Bar React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorToolbar, { modules, formats } from "../../../components/toolbar/Editor";

const { Title } = Typography

export default function Announcements() {

//  Add Announcements 
const [value,setValue] = useState("")
const { user } = useAuthContext()
const [isProcessing, setIsProcessing] = useState(false)
  

  const [allDocuments, setAllDocuments] = useState([])
  const [documents, setDocuments] = useState([])
  const [status, SetStatus] = useState("active")
//   const [todo, setTodo] = useState({})
//   const [isModalOpen, setIsModalOpen] = useState(false)
  const navigate = useNavigate()

//   Adding Announcements Function 
const handleAnnouncement = async (e) => {
    e.preventDefault()
    setIsProcessing(true)
    // let { location} = state
  

    if (!value) {
        setIsProcessing(false)
         return  message.error("Please enter the location.") }
    if (value.length < 10) { 
        setIsProcessing(false)
        return message.error("Description length is less than 10") }

    const Announ = {
      Announcement:value,
      status: "active",
      dateCreated: new Date().getTime(),
      id: Math.random().toString(36).slice(2),
      createdBy: {
        email: user.email,
        uid: user.uid,
      }
    }

    createDocument(Announ)
    
  }

  const createDocument = async (obj) => {
  try {
    await setDoc(doc(firestore, "Announcement", obj.id), obj);

    message.success("Announcement is added successfully")
  } catch (e) {
   message.error("Error, Something went wrong")
  }
  setIsProcessing(false)
  setValue("")
}

//   const handleChange = e => setTodo(s => ({ ...s, [e.target.name]: e.target.value }))
//   const handleDate = (_, date) => setTodo(s => ({ ...s, date }))

  const getTodos = async () => {

    const q = query(collection(firestore, "Announcement"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setAllDocuments(array)
    setDocuments(array)
  }

  useEffect(() => {
    getTodos()
  }, [])

  useEffect(() => {
    const filteredDocuments = allDocuments.filter(todo => todo.status === status)
    setDocuments(filteredDocuments)
  }, [allDocuments, status])

  const handleDelete = async (obj) => {

    try {
      await deleteDoc(doc(firestore, "Announcement", obj.id));

      let documentsAfterDelete = documents.filter(doc => doc.id !== obj.id)
      setAllDocuments(documentsAfterDelete)
      setDocuments(documentsAfterDelete)

      message.success("Deleted successfully")
    } catch (err) {
      console.error(err)
      message.error("Something went wrong while deleting Annoucement")
    }
  }

  return (
    <>
     <main>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-10">
            <div className="card py-5">
              <div className="card-body">

                <Form layout="vertical">
                <Title level={2} className='m-0 text-center'>Add Announcements</Title>

                <Divider />
                  <Form.Item label="Announcement" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          name='location'
                          onChange={setValue}
                          placeholder={"Write Announcements here..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                    <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >

                  <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleAnnouncement}>Add</Button>
                    </Col>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
      <div className='py-5'>
     
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1>Announcements</h1>
              <Select placeholder="Select status" onChange={status => SetStatus(status)}>
                {["active", "inactive"].map((status, i) => {
                  return <Select.Option key={i} value={status}>{status}</Select.Option>
                })}
              </Select>
            </div>
          </div>
          <Divider />

          <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Anouncements</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((obj, i) => {
                      return (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td><div dangerouslySetInnerHTML={{__html:obj.Announcement}}></div></td>
                          <td>{obj.dateCreated ? dayjs(obj.dateCreated).format("dddd, DD/MM/YYYY") : ""}</td>
                          <td>{obj.status}</td>
                          <td>
                            <Space>
                              <Tooltip title="Delete" color='red'><Button danger icon={<DeleteOutlined />} onClick={() => { handleDelete(obj) }} /></Tooltip>
                              <Tooltip title="Edit"><Button type="primary" icon={<EditOutlined />} onClick={() => { navigate(`/dashboard/announcement/${obj.id}`) }} /></Tooltip>
                            </Space>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
