import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Image, Input, Progress, Row, Select, Typography, message } from 'antd'

import { setDoc, doc, collection, query, getDocs } from "firebase/firestore";
import { firestore, storage } from 'config/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuthContext } from 'contexts/AuthContext';

// React Full Tool Bar React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorToolbar, { modules, formats } from "../../../components/toolbar/Editor";

const { Title } = Typography

const initialState = { product_name: "", model_number: "",category:"",voltage:"",capacity:"",short_description:""}

export default function Hero() {

  const { user } = useAuthContext()
  const [state, setState] = useState(initialState)
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(0)
  const [isProcessing, setIsProcessing] = useState(false)
  const [value, setValue] = useState("")
  const [allDocuments, setAllDocuments] = useState([])
  // const [documents, setDocuments] = useState([])

  const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }))
  // const handleDate = (_, date) => setState(s => ({ ...s, date }))

  const handleSubmit = async (e) => {
    e.preventDefault()

    let { product_name, model_number, category,voltage,capacity, short_description} = state

    if (!product_name) { return message.error("Please enter product name!") }
    if (short_description.length<10) { return message.error("Please enter more about product in short description!") }
    if (short_description.length>250) { return message.error("Please enter less about product in short description!") }
    if(!category){ return message.error("Please Select product Category!") }
    if(value.length<10){ return message.error("Please enter the description correctly!") }
    if(!file){ return message.error("Please upload the picture of product!") }

    const formData = {
      product_name, model_number,category,voltage,capacity, short_description ,description:value,
      status: "active",
      dateCreated: new Date().getTime(),
      id: Math.random().toString(36).slice(2),
      file: "",
      createdBy: {
        email: user.email,
        uid: user.uid,
      }
    }
    setIsProcessing(true)
    if (file) {
      if(file.size>100000){
        setIsProcessing(false)
        return message.error("File size must less than 100kb!")
      }
      uploadFile(formData)
    } else {
      createDocument(formData)
    }
  }

  const createDocument = async (formData) => {
    try {
      await setDoc(doc(firestore, "Products", formData.id), formData);
      // console.log('todo.id', formData.id)
      message.success("A new Product is added successfully!")
      setIsProcessing(false)
    } catch (e) {
      console.error("Error adding document: ", e);
      message.error("Something went wrong!")
      setIsProcessing(false)
    }
  }

  const uploadFile = (obj) => {
   
    const fileName = obj.id
    var fileExtension = file.name.split('.').pop();

    const storageRef = ref(storage, `images/${fileName}.${fileExtension}`);

    const uploadTask = uploadBytesResumable(storageRef, file);
    

    uploadTask.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.floor(progress))
      },
      (error) => {
        message.error("Something went wrong while uploading file")
        // Handle unsuccessful uploads
        setIsProcessing(false)
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let data = { ...obj,fileExtension, file: downloadURL }
          createDocument(data)
          setValue("")
          setState(initialState)
          setFile(null)
        });
      }
    );
   
  }
  const getCategories = async () => {

    const q = query(collection(firestore, "Categories"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setAllDocuments(array)
    // setDocuments(array)
  }

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className='m-0 text-center'>Add Product</Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Product Name">
                      <Input placeholder='Enter product name' value={state.product_name} name='product_name' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Model Number">
                      <Input placeholder='Enter the product model number...' value={state.model_number} name='model_number' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label="Categories">
                      <Select  value={state.category||undefined} placeholder="Select Category" onChange={category =>{ setState(s => ({ ...s, category }))
                    console.log('category', category)}}>
                        {allDocuments.map((obj, i) => {
                          return <Select.Option key={i} value={obj.Category_name}>{obj.Category_name}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Form.Item label="Image">
                      <Input type='file' placeholder='Upload picture' onChange={e => { setFile(e.target.files[0]) }} />
                    </Form.Item>
                    {isProcessing && file && <Progress percent={progress} showInfo={false} />}
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Item label="Preview">
                      {file && <Image src={URL.createObjectURL(file)} style={{ width: 100, height: 50 }} />}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Voltage">
                      <Input placeholder='Enter voltage of Battery' value={state.voltage} name='voltage' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Capacity">
                      <Input placeholder='Enter the capacity of battery in hours...' value={state.capacity} name='capacity' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Short Description">
                      <Input.TextArea placeholder='Write less than 50 words about product...' name='short_description' value={state.short_description} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                  <Form.Item label="Description" >
                    
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          style={{ height: 150 }}
                          name='description'
                          onChange={setValue}
                          placeholder={"Write about Product here..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >
                    <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleSubmit}>Add Product</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
