import React from 'react'
import {FaFacebookF} from '../../../node_modules/react-icons/fa6'
import {FaTwitter,FaInstagram} from '../../../node_modules/react-icons/fa'
// import {PiYoutubeLogo} from '../../../node_modules/react-icons/pi'
import {ImYoutube2} from '../../../node_modules/react-icons/im'

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <footer className='py-2 bg-primary'>
            <div className="container text-white">
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">
                    <div className="col mb-3">
                        <h3>About VOLTEC</h3>
                        <p className="text-white">VOLTEC is well Known Company that provide best electronic devices like batteries etc.</p>
                        <a href="https://www.facebook.com/profile.php?id=61553480411168" className='mx-2' target='_blank' rel="noopener noreferrer" ><FaFacebookF className='text-white fs-5' /></a>
                        <a href="https://twitter.com/Voltec_Group" className='mx-2' target='_blank' rel="noopener noreferrer" ><FaTwitter className='text-white fs-5' /></a>
                        <a href="https://www.instagram.com/voltec.group/" className='mx-2' target='_blank' rel="noopener noreferrer" ><FaInstagram className='text-white fs-5' /></a>
                        <a href="https://www.youtube.com/@VoltecGroup" className='mx-2' target='_blank' rel="noopener noreferrer" ><ImYoutube2 className='text-white fs-3' /></a>
                    </div>

                    <div className="col mb-3">

                    </div>

                    <div className="col mb-3">
                        <h5>Why VOLTEC</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Quality and Environment Policy</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Consumer Price List</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Export Catalogue</a></li>
                        </ul>
                    </div>

                    <div className="col mb-3">
                        <h5>About</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">About VOLTEC</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">News & Events</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Videos</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Careers</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Download Technical Sheets</a></li>
                        </ul>
                    </div>

                    <div className="col mb-3">
                        <h5>Support</h5>
                        <ul className="nav flex-column">
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">After sale service</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Blogs</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Companies</a></li>
                            <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-white fw-medium">Battery Test</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="mb-0 text-white text-center">&copy; {year} VOLTEC. All Rights Reserved.</p>

        </footer>
    )
}
