import React from 'react'
import Products from './Products'

export default function Index() {
    return (
        <main>
            <Products />
        </main>
    )
}
