import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import { Button, Col, Form, Input, Typography, message } from 'antd';
import { firestore } from '../../../config/firebase';
import { doc, setDoc } from "firebase/firestore";
import { useAuthContext } from 'contexts/AuthContext';
import 'react-quill/dist/quill.snow.css';

const { Title } = Typography;
const initialState = { fullname: "", email: "", Message: "" }

export default function Support() {
  const [state, setState] = useState(initialState)
  let [value, setValue] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const { isAuth } = useAuthContext()

  const handleChange = e => {
    setState(s => ({ ...s, [e.target.name]: e.target.value }))
  }

  let { fullname, email } = state;


  const handleSubmit = async (e) => {
    e.preventDefault();
   
    fullname = fullname.trim();
    value = value.trim()

    if (fullname.length < 3) {
      return message.error("Please fill the name correctly.")
    }
    if (!window.isEmail(email)) {
      return message.error("Enter the Email correctly!")
    }
    if (value.length < 10) {
      return message.error("Message contains atleast 10 characters.")
    }
    const formData = {
      fullname, email, Message:value,
      id: window.getRandomId(),
      dateCreated: new Date().getTime(),
    }

    setIsProcessing(true)

    if (isAuth) {
      try {
        await setDoc(doc(firestore, "messages", formData.id), formData);
        // console.log("Document written with ID: ", docRef.id);
        message.success("Your message is Successfully sended.")
        setState(initialState)
        setValue("")
      } catch (e) {
        // console.error("Error adding document: ", e);
        return message.error("Something went wrong, your message doesn't send.")
      }
    }
    else {
      message.error("Please login your account first!")
    }
    setIsProcessing(false)
  }

  return (
    <>
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-10">
            <div className="card">

              <div className=" card-body ">
                <Form layout='vertical'>

                  <Title level={2} className='my-4 text-center'>Support</Title>
                  <div className="row d-flex">
                    <Col xs={24} md={12}>
                      <Form.Item label="Full Name" className=''>
                        <Input placeholder="Your Full Name" value={fullname} name='fullname' onChange={handleChange} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="Email" >
                        <Input placeholder="Your Email" value={email} name='email' onChange={handleChange} />
                      </Form.Item>
                    </Col>
                  </div>
                  <div className="row d-flex">
                    <Form.Item label="Message" >
                      <ReactQuill className='custom-react-quill' placeholder='Write your message...' style={{ height: 150 }} value={value} onChange={setValue} />
                    </Form.Item>
                  </div>

                  <Form.Item>
                    <Button type="primary" htmlType='submit' onClick={handleSubmit} className='w-100 mt-5' style={{ textAlign: 'center' }} loading={isProcessing}>
                      Send
                    </Button>
                  </Form.Item>

                </Form>
                

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}



