import React from 'react'


export default function Index() {
    return (
        <main className='d-flex justify-content-center align-items-center'>
            <h1>Under Construction</h1>
        </main>
    )
}
