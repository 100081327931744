import React from 'react'
import PriceList from './PriceList'

export default function index() {
  return (
    <>
    <PriceList />

    </>
  )
}
