import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Input, Row, Select, Typography, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
// import dayjs from 'dayjs'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'
// React Full Tool Bar React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorToolbar, { modules, formats } from "../../../components/toolbar/Editor";

const { Title } = Typography

export default function UpdateCareers() {

  const [state, setState] = useState({})
  const [value,setValue] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }))
//   const handleDate = (_, date) => setState(s => ({ ...s, date }))

  const getDocument = useCallback(async () => {
    const docRef = doc(firestore, "Careers", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()){
      const obj = docSnap.data()
      setValue(obj.description)
      setState(obj)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("Job is not found!")
    }
  }, [params.id])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let {  status,jobTitle,location,contact } = state
    const UpJob = {
      ...state,
      jobTitle,location,contact,
       description:value,  status,
      dateModified: new Date().getTime(),
    }
// console.log('Uploc', Uploc)
    setIsProcessing(true)
    try {
      await setDoc(doc(firestore, "Careers", UpJob.id), UpJob);
      message.success("Category updated successfully!")
      navigate("/dashboard/careers")
    } catch (e) {
      message.error('Something went wrong with updating Job!')
    }
    setIsProcessing(false)
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className='m-0 text-center'>Update Job</Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter={16}>
                
                <Col xs={24} lg={12}>
                    <Form.Item label="Job Title">
                      <Input placeholder='Enter the title of job' value={state.jobTitle} name='jobTitle' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Job location">
                      <Input placeholder='Enter the location' value={state.location} name='location' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Contact">
                      <Input placeholder='Enter the contact for queries or CV' value={state.contact} name='contact' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                   
                <Col xs={24} md={12}>
                    <Form.Item label="Status">
                      <Select value={state.status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  <Col xs={24} lg={24}>
                  <Form.Item label="Description" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                        style={{height:150}}
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          name='description'
                          onChange={setValue}
                          placeholder={"Write address the offices..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >
                    <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleSubmit}>Update Job</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
