import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Image, Input, Progress, Row, Select, Typography, message} from "antd";
import { useNavigate, useParams } from "react-router-dom";
// import dayjs from 'dayjs'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
} from "firebase/firestore";
import { firestore, storage } from "config/firebase";

// React Full Tool Bar React Quill
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorToolbar, {
  modules,
  formats,
} from "../../../components/toolbar/Editor";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const { Title } = Typography;

// const initialState = { title: "", location: "", date: "", status: "", description: "" }
const initialState = {
  product_name: "",
  model_number: "",
  category: "",
  voltage: "",
  capacity: "",
  status: "",
  short_description:""
};

export default function UpdateProdu () {
  const [state, setState] = useState(initialState);
  const [isProcessing, setIsProcessing] = useState(false);
  const [file, setFile] = useState(null);
  const [value, setValue] = useState("");
  const [progress, setProgress] = useState(0);
  const [allDocuments, setAllDocuments] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const handleChange = (e) =>
    setState((s) => ({ ...s, [e.target.name]: e.target.value }));

  const getDocument = useCallback(async () => {
    const docRef = doc(firestore, "Products", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const product = docSnap.data();
      setState(product);
      setValue(product.description);
    } else {
      // docSnap.data() will be undefined in this case
      message.error("Product not found");
    }
  }, [params.id]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let { product_name, model_number, category, voltage, capacity, status,short_description } =
      state;

    if (!product_name) {
      return message.error("Please enter product name!");
    }
    if (!category) {
      return message.error("Please Select product Category!");
    }
    if (value.length < 10) {
      return message.error("Please enter the description correctly!");
    }
    if (short_description.length<10) { return message.error("Please enter more about product in short description!") }
    if (short_description.length>250) { return message.error("Please enter less about product in short description!") }

    const formData = {
      ...state,
      product_name,
      model_number,
      category,
      voltage,
      capacity,
      status,
      short_description,
      description: value,
      dateModified: new Date().getTime(),
    };
    setIsProcessing(true);
    if (file) {
      if (file.size > 100000) {
        setIsProcessing(false);
        return message.error("File size must less than 100kb!");
      }
      uploadFile(formData);
    } else {
        createDocument(formData)
    }
  };
  const createDocument = async (formData) => {
    try {
      await setDoc(doc(firestore, "Products", formData.id), formData);
      message.success("Product is Updated successfully!");
      setIsProcessing(false);
      navigate("/dashboard/products")
    } catch (e) {
      //   console.error("Error adding document: ", e);
      message.error("Something went wrong!");
      setIsProcessing(false);
    }
  };

  const uploadFile = (obj) => {
    const fileName = obj.id;
    var fileExtension = file.name.split(".").pop();

    const storageRef = ref(storage, `images/${fileName}.${fileExtension}`);

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.floor(progress));
      },
      (error) => {
        message.error("Something went wrong while uploading file");
        // Handle unsuccessful uploads
        setIsProcessing(false);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          let data = { ...obj, fileExtension, file: downloadURL };
          createDocument(data);
          setValue("");
          setState(initialState);
          setFile(null);
        });
      }
    );
  };
  const getCategories = async () => {
    const q = query(collection(firestore, "Categories"));

    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data();
      array.push(data);
    });
    setAllDocuments(array);
    // setDocuments(array)
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className="m-0 text-center">
                Update Product
              </Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter={16}>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Product Name">
                      <Input
                        placeholder="Enter product name"
                        value={state.product_name}
                        name="product_name"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item label="Model Number">
                      <Input
                        placeholder="Enter the product model number..."
                        value={state.model_number}
                        name="model_number"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label="Categories">
                      <Select
                        value={state.category || undefined}
                        placeholder="Select Category"
                        onChange={(category) => {
                          setState((s) => ({ ...s, category }));
                         
                        }}
                      >
                        {allDocuments.map((obj, i) => {
                          return (
                            <Select.Option key={i} value={obj.Category_name}>
                              {obj.Category_name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Form.Item label="Image">
                      <Input
                        type="file"
                        placeholder="Upload picture"
                        onChange={(e) => {
                          setFile(e.target.files[0]);
                        }}
                      />
                    </Form.Item>
                    {isProcessing && file && (
                      <Progress percent={progress} showInfo={false} />
                    )}
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Item label="Preview">
                      {file && (
                        <Image
                          src={URL.createObjectURL(file)}
                          style={{ width: 100, height: 50 }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="Voltage">
                      <Input
                        placeholder="Enter voltage of Battery"
                        value={state.voltage}
                        name="voltage"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="Capacity">
                      <Input
                        placeholder="Enter the capacity of battery in hours..."
                        value={state.capacity}
                        name="capacity"
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="Status">
                      <Select
                        value={state.status}
                        onChange={(status) =>
                          setState((s) => ({ ...s, status }))
                        }
                      >
                        {["active", "inactive"].map((status, i) => {
                          return (
                            <Select.Option key={i} value={status}>
                              {status}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Short Description">
                      <Input.TextArea placeholder='Write less than 50 words about product ...' name='short_description' value={state.short_description} onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Description">
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                          className="custom-react-quill"
                          theme="snow"
                          value={value}
                          style={{ height: 150 }}
                          name="description"
                          onChange={setValue}
                          placeholder={"Write about category here..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    md={{ span: 12, offset: 6 }}
                    lg={{ span: 8, offset: 8 }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-100"
                      loading={isProcessing}
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
