import React from 'react'
import Categories from './Categories'

const index = () => {
  return (
    <>
    <Categories />
    
    </>
  )
}

export default index