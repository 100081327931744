import { Divider } from 'antd';
import { firestore } from 'config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'

const Careers = () => {
  const [documents, setDocuments] = useState([]);
  const getLocations = async () => {
    const q = query(
      collection(firestore, "Careers"),
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data();
      array.push(data);
    });
    setDocuments(array);
  };
  useEffect(() => {
    getLocations();
  }, []);
  return (
    <>
    <div className="container">
      <div className="row">
        <h1 className='text-center my-4'>Jobs</h1>
        <Divider />
              <div className="col">
                <div className="px-5 pb-5">
                <div className="table-responsive">
  <table className="table align-middle">
    <thead>
      <tr>
        <th>#</th>
        <th>Title</th>
        <th>Location</th>
        <th>Contact for CV or queries</th>
        <th>Job Description</th>
      </tr>
    </thead>
    <tbody>
    
      {documents.map((obj, i) => {
                    return (
                      
      <tr key={i}>
        <td>{i+1}</td>
        <td>{obj.jobTitle}</td>
        <td>{obj.location}</td>
        <td>{obj.contact}</td>
        <td dangerouslySetInnerHTML={{__html:obj.description}}></td>
        
      </tr>
   
                    );

                  })}

</tbody>
  </table>
</div>
                </div>
              </div>
            </div>
   </div>
    </>
  )
}

export default Careers