import React, { useState } from 'react'
import { message } from 'antd'
import { auth } from 'config/firebase'
import { useAuthContext } from 'contexts/AuthContext'
import { signOut } from 'firebase/auth'
import { Link, useLocation } from 'react-router-dom'
// import { Dropdown } from 'antd';

// imgs 
import Logo from '../../assets/images/logo.png'



export default function Navbar({ history }) {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(location.pathname)
  const { isAuth, dispatch } = useAuthContext()
  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }
  // const items = [
  //   {
  //     key: '1',
  //     label: (
  //       <Link to="/directors-message" className="nav-link fw-medium"
  //       onClick={() => handleTabClick('dir-msg')}
  //       style={{ color: activeTab === 'dir-msg' ? '#0103FC' : 'black' }}
  //     >Directors Message</Link>
  //     ),
  //   },
  //   {
  //     key: '2',
  //     label: (
  //       <Link to="/certificates" className="nav-link fw-medium"
  //                       onClick={() => handleTabClick('certificates')}
  //                       style={{ color: activeTab === 'certificates' ? '#0103FC' : 'black' }}
  //                     >Certificates</Link>
  //     ),
  //   },
  //   {
  //     key: '3',
  //     label: (
  //       <Link to="/export-awards" className="nav-link fw-medium"
  //       onClick={() => handleTabClick('export-award')}
  //       style={{ color: activeTab === 'export-award' ? '#0103FC' : 'black' }}
  //     >Export Awards</Link>
  //     ),
  //   },
  // ];

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        message.success("Signout successful")
        dispatch({ type: "SET_LOGGED_OUT" })
      })
      .catch(err => {
        message.error("Signout not successful")
      })
  }
  return (
    <header>
      <nav className="navbar navbar-expand-lg p-0">
        <div className="container">
          <Link to="/" className="navbar-brand"><img src={Logo} alt="logo" width="120px" /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            </ul>
            <div className="d-flex">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item mx-1"
                >
                  <Link to="/" className="nav-link fw-medium"
                    onClick={() => handleTabClick('/')}
                    style={{ color: activeTab === '/' ? '#0103FC' : 'black', cursor: 'pointer' }}>Home</Link>
                </li>
                {/* <Dropdown menu={{ items, }} placement="bottomLeft" arrow>
                  <li className="nav-item mx-1" >
                    <div  className="nav-link fw-medium"
                      onClick={() => handleTabClick('why-voltec')}
                      style={{ color: activeTab === 'why-voltec' ||activeTab === 'certificates' || activeTab==='dir-msg' || activeTab==='export-award' ? '#0103FC' : 'black' }}
                    >Why VOLTEC?</div>
                  </li>
                </Dropdown> */}
               
                <li className="nav-item mx-1">
                  <Link to="/products" className="nav-link fw-medium"
                    onClick={() => handleTabClick('/products')}
                    style={{ color: activeTab === '/products' ? '#0103FC' : 'black' }}>Products</Link>
                </li>
                <li className="nav-item mx-1">
                  <Link to="/price-list" className="nav-link fw-medium"
                    onClick={() => handleTabClick('/price-list')}
                    style={{ color: activeTab === '/price-list' ? '#0103FC' : 'black' }}>Price list</Link>
                </li>
               
                <li className="nav-item mx-1">
                  <Link to="/about" className="nav-link fw-medium"
                    onClick={() => handleTabClick('/about')}
                    style={{ color: activeTab === '/about' ? '#0103FC' : 'black' }}>About</Link>
                </li>
                <li className="nav-item mx-1">
                  <Link to="/contact" className="nav-link fw-medium"
                    onClick={() => handleTabClick('/contact')}
                    style={{ color: activeTab === '/contact' ? '#0103FC' : 'black' }}>Contact Us</Link>
                </li>
              {!isAuth
                ? <></>
                : <>
                   <li className="nav-item mx-1 list-unstyled">
                  <Link to="/dashboard/" className="nav-link fw-medium"
                    onClick={() => handleTabClick('/dashboard')}
                    style={{ color: activeTab === '/dashboard' ? '#0103FC' : 'black' }}>Dashboard</Link>
                </li>
                  <button className='btn btn-danger ms-2' onClick={handleLogout}>Logout</button>
                </>
              }
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}
