import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Divider, Form, Row, Select, Typography, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
// import dayjs from 'dayjs'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'
// React Full Tool Bar React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorToolbar, { modules, formats } from "../../../components/toolbar/Editor";

const { Title } = Typography

// const initialState = {  location: "", dateCreated: "", status: "" }

export default function UpdateAnouncements() {

  const [state, setState] = useState({})
  const [value,setValue] = useState("")
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

//   const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }))
//   const handleDate = (_, date) => setState(s => ({ ...s, date }))

  const getDocument = useCallback(async () => {
    const docRef = doc(firestore, "Announcement", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()){
      const todo = docSnap.data()
      setValue(todo.Announcement)
      setState(todo)
    } else {
      // docSnap.data() will be undefined in this case
      message.error("location not found!")
    }
  }, [params.id])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  const handleSubmit = async (e) => {
    e.preventDefault()
    let {  status } = state
    const UpAnn = {
      ...state,
       Announcement:value,  status,
      dateModified: new Date().getTime(),
    }
// console.log('Uploc', Uploc)
    setIsProcessing(true)
    try {
      await setDoc(doc(firestore, "Announcement", UpAnn.id), UpAnn);
      message.success("Announcement updated successfully")
      navigate("/dashboard/announcement")
    } catch (e) {
      message.error('Something went wrong with updating announcement.')
    }
    setIsProcessing(false)
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="card p-3 p-md-4">
              <Title level={2} className='m-0 text-center'>Update Announcement</Title>

              <Divider />

              <Form layout="vertical">
                <Row gutter={16}>
                  
                  <Col xs={24} lg={24}>
                  <Form.Item label="Locations" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          name='location'
                          onChange={setValue}
                          placeholder={"Write address the offices..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  {/* <Col xs={24} lg={12}>
                    <Form.Item label="Date">
                      <DatePicker className='w-100' value={state.date ? dayjs(state.date) : ""} onChange={handleDate} />
                    </Form.Item>
                  </Col> */}
                  <Col xs={24} lg={12}>
                    <Form.Item label="Status">
                      <Select value={state.status} onChange={status => setState(s => ({ ...s, status }))}>
                        {["active", "inactive"].map((status, i) => {
                          return <Select.Option key={i} value={status}>{status}</Select.Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col span={24}>
                    <Form.Item label="Description">
                      <Input.TextArea placeholder='Input your description' name='description' value={state.description} onChange={handleChange} />
                    </Form.Item>
                  </Col> */}

                  <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >
                    <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleSubmit}>Update Announcement</Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
