import React from 'react'
import Hero from './Hero'
// import Hero from './Hero'

export default function Index() {
    return (
        <>
            <Hero />
        </>
    )
}
