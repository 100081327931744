import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'

import Home from "./Home"
import About from "./About"
import Contact from "./Contact"
import NoPage from 'components/NoPage/NoPage'
import Products from './Products'
import ViewProducts from './Products/ViewProduct'
import Support from './Support'
import Careers from './Careers'
import Pricelist from './PriceList'

export default function Index() {
    return (
        <>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='contact' element={<Contact />} />
                <Route path='products' element={<Products />} />
                <Route path='products/:id' element={<ViewProducts />} />
                <Route path='support' element={<Support />} />
                <Route path='careers' element={<Careers />} />
                <Route path='price-list' element={<Pricelist />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
            <Footer />
        </>
    )
}
