import React from 'react'
import Navbar from './Navbar'
import TopBar from './TopBar'

export default function index() {
    return (

        <>
        <TopBar />
        <Navbar />
        </>
    )
}
