import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login'
import NoPage from 'components/NoPage'
import ForgotPassword from './ForgotPassword'

export default function Index() {
    return (
        <Routes>
            <Route path='login' element={<Login />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            {/* <Route path='reset-password' element={<ResetPassword />} /> */}
            <Route path="*" element={<NoPage />} />
        </Routes>
    )
}
