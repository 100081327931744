import React from 'react'
import Hero from './Hero'

export default function Index() {
    return (
        <main>
            <Hero />
        </main>
    )
}
