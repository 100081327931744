import React from 'react'
import NoPage from './NoPage'

export default function Index() {
  return (
    <>
    <NoPage />
    </>
  )
}
