import './App.scss';
import "bootstrap/dist/js/bootstrap.bundle";
import Logo from '../src/assets/images/logo.png'
import { useAuthContext } from 'contexts/AuthContext';
import './config/globle'
import Routes from "pages/Routes"
// import 'owl.carousel';

function App() {

  const { isAppLoading } = useAuthContext()

  if (isAppLoading)
    return (
      // <div className="loader-container">
      //   <span className="loader"></span>
      // </div>
      // <!-- ======================= Screen Loader ============================== -->
      // <!--? Preloader Start -->
      <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
          <div className="preloader-inner position-relative">
            <div className="preloader-circle"></div>
            <div className="preloader-img pere-text">
              <img src={Logo} alt="logo" />
            </div>
          </div>
        </div>
    </div>
    )

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
