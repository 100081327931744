import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { message} from "antd";
// import dayjs from 'dayjs'
import { doc,getDoc } from "firebase/firestore";
import { firestore} from "config/firebase";

const initialState = { product_name: "", model_number: "", category: "", voltage: "", capacity: "", status: "", short_description:"", description:"" };

export default function ViewProduct() {
  const [state, setState] = useState(initialState);
  const params = useParams();
  const getDocument = useCallback(async () => {
    const docRef = doc(firestore, "Products", params.id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const product = docSnap.data();
      setState(product);
    } else {
      // docSnap.data() will be undefined in this case
      message.error("Product not found!");
    }
  }, [params.id]);

  useEffect(() => {
    getDocument();
  }, [getDocument]);

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-6">
            <h4><span className="text-primary">Name</span>: {state.product_name}</h4>
            <h4><span className="text-primary">Category</span>: {state.category}</h4>
            <h4><span className="text-primary">Voltage</span>: {state.voltage}</h4>
            <h4><span className="text-primary">Capacity</span>: {state.capacity}</h4>
          </div>
          <div className="col-md-6 text-end">
            <img src={state.file} className="img-fluid" alt="Product img" />
          </div>
        </div>
        <div className="row mt-3">
          <h4 className="text-primary">About Product</h4>
          <div dangerouslySetInnerHTML={{__html:state.description}}></div>
        </div>
      </div>
    </>
  );
}
