// export default Courses
import React, { useState } from 'react'
import { Button, Form, Typography, message, Divider, Col } from 'antd';
// import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined } from '@ant-design/icons';
import { useAuthContext } from 'contexts/AuthContext'
// import { createUserWithEmailAndPassword } from 'firebase/auth'
import {  firestore } from 'config/firebase'
import { doc,  setDoc } from 'firebase/firestore'
// React Full Tool Bar React Quill
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorToolbar, { modules, formats } from "../../../components/toolbar/Editor";

const { Title } = Typography
                
// const initialState = { location: ""}

export default function Contact() {
  // const [state, setState] = useState(initialState)
  const [value,setValue] = useState("")
  const { user } = useAuthContext()
  const [isProcessing, setIsProcessing] = useState(false)

  // const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }))

  const handleLocation = async (e) => {
    e.preventDefault()
    setIsProcessing(true)
    // let { location} = state
  

    if (!value) {
        setIsProcessing(false)
         return  message.error("Please enter the location.") }
    if (value.length < 10) { 
        setIsProcessing(false)
        return message.error("Description length is less than 10") }

    const loc = {
      location:value,
      status: "active",
      dateCreated: new Date().getTime(),
      id: Math.random().toString(36).slice(2),
      createdBy: {
        // fullName: user.name,
        email: user.email,
        uid: user.uid,
      }
    }

    createDocument(loc)
    
  }

  const createDocument = async (loc) => {
  try {
    await setDoc(doc(firestore, "locations", loc.id), loc);
    message.success("New location is added successfully")
  } catch (e) {
   message.error("Error,something went wrong")
  }
  setIsProcessing(false)
  setValue("")
}

  return (
    <main>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-10">
            <div className="card py-5">
              <div className="card-body">

                <Form layout="vertical">
                <Title level={2} className='m-0 text-center'>Add Locations</Title>

                <Divider />
                  {/* <Form.Item label="Description">
                  <ReactQuill className='custom-react-quill' value={value} onChange={setValue} />
                  </Form.Item> */}
                  <Form.Item label="Locations" >
                      <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                         className='custom-react-quill'
                          theme="snow"
                          value={value}
                          name='location'
                          onChange={setValue}
                          placeholder={"Write address the offices..."}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </Form.Item>
                    <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >

                  <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleLocation}>Add</Button>
                    </Col>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
