import React from 'react'
import Locations from './Locations'

const index = () => {
  return (
    <>
    <Locations />
    </>
  )
}

export default index