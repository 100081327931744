import React from 'react'

export default function Hero() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col">
            <h1 className="text-center my-5 py-5">Under Construction</h1>
          </div>
        </div>
      </div>
    </>
  )
}
