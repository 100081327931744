import React, { useEffect, useState } from 'react'
import {  Button, Divider, Form, Select, Space,Typography, Tooltip, message, Row, Input, Col } from 'antd'
import { DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { useAuthContext } from 'contexts/AuthContext'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query, setDoc } from 'firebase/firestore'
import { firestore } from 'config/firebase'
// import { useAuthContext } from 'contexts/AuthContext'

const { Title } = Typography
const initialState = {productName:"",modelNumber:"",price:""}
export default function PriceList() {

//  Add Price List
const { user } = useAuthContext()
const [isProcessing, setIsProcessing] = useState(false)
const [allDocuments, setAllDocuments] = useState([])
const [documents, setDocuments] = useState([])
const [status, SetStatus] = useState("active")
const [state,setState] =useState(initialState)
const navigate = useNavigate()

const handleChange = e => setState(s => ({ ...s, [e.target.name]: e.target.value }))
//   Adding Announcements Function 
const handleCategory = async (e) => {
  let {productName,modelNumber,price} = state
    e.preventDefault()
    setIsProcessing(true)
    // let { location} = state
  
    if(!productName){
      setIsProcessing(false)
         return  message.error("Please enter the Product name.")
    }
    if (!modelNumber) {
        setIsProcessing(false)
         return  message.error("Please enter the model number.") }
    if (!price) { 
        setIsProcessing(false)
        return message.error("Please enter the price!") }

    const formData = {
      productName,
      modelNumber,
      price,
      status: "active",
      dateCreated: new Date().getTime(),
      id: Math.random().toString(36).slice(2),
      createdBy: {
        email: user.email,
        uid: user.uid,
      }
    }

    createDocument(formData)
    
  }

  const createDocument = async (obj) => {
  try {
    await setDoc(doc(firestore, "PriceList", obj.id), obj);

    message.success("Added successfully")
  } catch (e) {
   message.error("Error, Something went wrong")
  }
  setIsProcessing(false)
  setState(initialState)
}

  const getPriceList = async () => {

    const q = query(collection(firestore, "PriceList"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setAllDocuments(array)
    setDocuments(array)
  }
  useEffect(() => {
    getPriceList()
  }, [])

  useEffect(() => {
    const filteredDocuments = allDocuments.filter(obj => obj.status === status)
    setDocuments(filteredDocuments)
  }, [allDocuments, status])

  const handleDelete = async (obj) => {

    try {
      await deleteDoc(doc(firestore, "PriceList", obj.id));

      let documentsAfterDelete = documents.filter(doc => doc.id !== obj.id)
      setAllDocuments(documentsAfterDelete)
      setDocuments(documentsAfterDelete)

      message.success("Deleted successfully")
    } catch (err) {
      console.error(err)
      message.error("Something went wrong while deleting.")
    }
  }

  return (
    <>
     <main>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-10">
            <div className="card py-5">
              <div className="card-body">

                <Form layout="vertical">
                <Title level={2} className='m-0 text-center'>Price List</Title>

                <Divider />
                <Row gutter="16">
                <Col xs={24} lg={8}>
                    <Form.Item label="Product Name">
                      <Input placeholder='Enter the product name...' value={state.productName} name='productName' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="Model Number">
                      <Input placeholder='Enter the model number...' value={state.modelNumber} name='modelNumber' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Form.Item label="Price">
                      <Input placeholder='Enter the price of the product...' value={state.price} name='price' onChange={handleChange} />
                    </Form.Item>
                  </Col>
                    </Row>
                    <Divider />
                    <Col xs={24} md={{ span: 12, offset: 6 }} lg={{ span: 8, offset: 8 }} >

                  <Button type='primary' htmlType='submit' className='w-100' loading={isProcessing} onClick={handleCategory}>Add to Price list</Button>
                    </Col>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
      <div className='py-5'>
     
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1>Price List</h1>
              <Select placeholder="Select status" onChange={status => SetStatus(status)}>
                {["active", "inactive"].map((status, i) => {
                  return <Select.Option key={i} value={status}>{status}</Select.Option>
                })}
              </Select>
            </div>
          </div>
          <Divider />

          <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product Name</th>
                      <th>Model Number</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((obj, i) => {
                      return (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td>{obj.productName}</td>
                          <td>{obj.modelNumber}</td>
                          <td>{obj.price}</td>
                          <td>{obj.dateCreated ? dayjs(obj.dateCreated).format("dddd, DD/MM/YYYY") : ""}</td>
                          <td>{obj.status}</td>
                          <td>
                            <Space>
                              <Tooltip title="Delete" color='red'><Button danger icon={<DeleteOutlined />} onClick={() => { handleDelete(obj) }} /></Tooltip>
                              <Tooltip title="Edit"><Button type="primary" icon={<EditOutlined />} onClick={() => { navigate(`/dashboard/price-list/${obj.id}`) }} /></Tooltip>
                            </Space>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
