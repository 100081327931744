import React, { useEffect, useState } from 'react'
import { Avatar, Button,  Divider, Image, Select, Space, Tooltip, message } from 'antd'
import { DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons"
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { collection, deleteDoc, doc, getDocs, query} from 'firebase/firestore'
import { firestore, storage } from 'config/firebase'
import { deleteObject, ref } from 'firebase/storage'

export default function Products() {

  // const { user } = useAuthContext()
  const [allDocuments, setAllDocuments] = useState([])
  const [documents, setDocuments] = useState([])
  const [status, SetStatus] = useState("active")
  const navigate = useNavigate()

  const getProducts = async () => {
    const q = query(collection(firestore, "Products"))

    const querySnapshot = await getDocs(q);
    const array = []
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data()
      array.push(data)
    });
    setAllDocuments(array)
    setDocuments(array)
  }
  useEffect(() => {
    const filteredDocuments = allDocuments.filter(obj => obj.status === status)
    setDocuments(filteredDocuments)
  }, [allDocuments, status])


  const handleDelete = async (obj) => {
    // Create a reference to the file to delete
const desertRef = ref(storage, `images/${obj.id + '.' + obj.fileExtension}`);

// Delete the file
deleteObject(desertRef).then(() => {
  message.success("File deleted Successfully!")
}).catch((error) => {
  message.error("Something went wrong with deleting")
});
    try {
      await deleteDoc(doc(firestore, "Products", obj.id));

      let documentsAfterDelete = documents.filter(doc => doc.id !== obj.id)
      setAllDocuments(documentsAfterDelete)
      setDocuments(documentsAfterDelete)

      message.success("Product deleted successfully!")
    } catch (err) {
      console.error(err)
      message.error("something went wrong while deleting product!")
    }
  }
  
  useEffect(() => {
    getProducts()
  },[])

  return (
    <>
      <div className='py-5'>
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1>Products</h1>
              <Select placeholder="Select status" onChange={status => SetStatus(status)}>
                {["active", "inactive"].map((status, i) => {
                  return <Select.Option key={i} value={status}>{status}</Select.Option>
                })}
              </Select>
            </div>
          </div>
          <Divider />

          <div className="row">
            <div className="col">
              <div className="table-responsive">
                <table className="table table-striped align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Model</th>
                      <th>Category</th>
                      <th>Voltage</th>
                      <th>Capacity</th>
                      <th>Description</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((obj, i) => {
                      return (
                        <tr key={i}>
                          <th>{i + 1}</th>
                          <td>{obj.file ? <Image src={obj.file} className='rounded-circle' style={{ width: 50 }} /> : <Avatar size={50} icon={<UserOutlined />} />}</td>
                          <td>{obj.product_name}</td>
                          <td>{obj.model_number}</td>
                          <td>{obj.category}</td>
                          <td>{obj.voltage}</td>
                          <td>{obj.capacity}</td>
                          <td><div dangerouslySetInnerHTML={{__html:obj.short_description}}></div></td>
                          <td>{obj.dateCreated ? dayjs(obj.date).format("dddd, DD/MM/YYYY") : ""}</td>
                          <td>{obj.status}</td>
                          <td>
                            <Space>
                              <Tooltip title="Delete" color='red'><Button danger icon={<DeleteOutlined />} onClick={() => { handleDelete(obj) }} /></Tooltip>
                              <Tooltip title="Edit"><Button type="primary" icon={<EditOutlined />} onClick={() => { navigate(`/dashboard/products/${obj.id}`) }} /></Tooltip>
                            </Space>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </>
  )
}
