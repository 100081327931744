import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "config/firebase";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// Images 
import img1 from '../../../assets/images/machines.webp';
import img2 from '../../../assets/images/charging.webp';
import img3 from '../../../assets/images/cell.webp';

export default function Hero() {
  const [documents, setDocuments] = useState([]);
  const containerStyle = {
    textAlign: 'justify',
  };

  const getLocations = async () => {
    const q = query(
      collection(firestore, "Announcement"),
      where("status", "==", "active")
    );
    const querySnapshot = await getDocs(q);
    const array = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let data = doc.data();
      array.push(data);
    });
    setDocuments(array);
  };
  useEffect(() => {
    getLocations();
  }, []);
  return (
    <>
      <main>
      <section className="background ">
      <div className="conatiner-fluid">
        <div className="row d-flex justify-content-between align-items-center  w-100 m-0">
          <div className="col-lg-5 col-md-6 col-11 offset-1 pt-md-2 pt-5">
            <div className="banner-area my-2 me-lg-1">
              <h1 className="text-primary">
                  Power Your Ride
              </h1>
              
              {/* <!-----para area===========================================================--> */}
              <p className="pb-md-0 pb-4">
                <span className="text-primary"><strong>VOLTEC</strong></span> is a company that provide Electric Batteries which are made of lithium. <strong>Lithium batteries</strong> (Li-ion) have many desirable characteristics such as high efficiencies, a long cycle life, high energy density, and high power density 
              </p>

            </div>
          </div>

          {/* <!-----------------------------image-slider==================================================--> */}
          <div className="col-md-6 p-0">
          <OwlCarousel className='owl-theme w-100' loop margin={10} items={1} autoplay dots={false}>
    <div className='item '>
        <img src={img1} className="img-fluid" style={{height:"350px"}} alt="img1" />
    </div>
    <div className='item'>
    <img src={img2} className="img-fluid" style={{height:"350px"}} alt="img1" />
    </div>
    <div className='item'>
    <img src={img3} className="img-fluid" style={{height:"350px"}} alt="img1" />
    </div>
   
</OwlCarousel>
          </div>
        </div>
      </div>
    </section>
    <div className="container my-5">
      <div className="row">
        <h1 className="text-center text-primary my-2" style={containerStyle}>About Lithium Batteries</h1>
        <p>Lithium batteries are a type of rechargeable battery that uses lithium ions as the main component of its electrochemical cells. These batteries have become widely popular due to their high energy density, long cycle life, and lightweight design. Here are some key characteristics of lithium batteries:
        </p>
        <p>
          
<strong>High Energy Density:</strong> Lithium batteries have a high energy density, which means they can store a significant amount of energy in a relatively small and lightweight package. This makes them ideal for applications where space and weight are critical factors.
        </p>
<p>

 <strong>Rechargeable:</strong> Lithium batteries are rechargeable, allowing them to be used multiple times before reaching the end of their useful life. This is in contrast to non-rechargeable lithium batteries, such as lithium primary cells.
</p>
<p>


<strong>Long Cycle Life:</strong> Lithium-ion batteries typically have a longer cycle life compared to many other rechargeable battery types. A cycle refers to one complete charge and discharge cycle, and lithium batteries can endure numerous cycles before their capacity starts to decline significantly.
</p>
<p>
<strong>Low Self-Discharge Rate:</strong> Lithium batteries have a relatively low self-discharge rate, meaning they lose less energy when not in use compared to some other rechargeable batteries. This makes them suitable for applications where long periods of inactivity between uses are common.

</p>
      </div>
    </div>
        <div className="py-2">
          <div className="container">
                <h1 className="text-center">Important Announcements</h1>
            <Divider />
            <div className="row">
              <div className="col">
                <div className="px-5 pb-5">
                  {documents.map((obj, i) => {
                    return (
                      <div key={i}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: obj.Announcement,
                            }}
                          ></div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
