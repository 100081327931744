import React from 'react'
import Careers from './Careers'


const index = () => {
  return (
    <>
    <main>

    <Careers />
    </main>
    </>
  )
}

export default index